body {
    background-color: rgb(231, 231, 231) !important;
}

.ui.inverted.top.fixed.menu {
    background: linear-gradient(108deg, rgba(53,77,201,1) 0%, rgba(100,53,201,1) 16%, rgba(163,53,201,1) 86%)!important;
}


ui.inverted.sidebar.menu {
    background: linear-gradient(108deg, rgba(53,77,201,1) 0%, rgba(100,53,201,1) 16%, rgba(163,53,201,1) 86%)!important;
}

/*home page styles*/

.masthead {
    display: flex;
    align-items: center;
    background: #2b5876!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4e4376, #2b5876)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4e4376, #2b5876)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
    margin-left: 0.5em;
}

.masthead h1.ui.header {
    font-size: 4em;
    font-weight: normal;
}

.masthead h2 {
    font-size: 1.7em;
    font-weight: normal;
}

@-moz-document url-prefix() {
    .fix-position-moz-DateTimePicker > div > input {
        border: none !important;
        bottom: 7px !important;
    }
}

/*end home page styles*/

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
    background-color: teal !important
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
    background-color: grey;
}

.yellowedBackground {
    background: rgb(131,58,180)!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(90deg,  rgb(131, 58, 180) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(90deg, rgb(131, 58, 180) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
}

.my-navbar {
    background: #24C6DC;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #514A9D, #24C6DC);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #514A9D, #24C6DC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.vice-city {
    background: #00B4DB;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0083B0, #00B4DB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.redBackground {
    background: #ED213A!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #93291E, #ED213A)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #93291E, #ED213A)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.blueBackground {
    background: #2193b0!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #6dd5ed, #2193b0)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #6dd5ed, #2193b0)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.purpleBackground {
    background: #ff00cc!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ff00cc, #333399)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ff00cc, #333399)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

.tealBackground {
    background: #00C9FF!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #92FE9D, #00C9FF)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #92FE9D, #00C9FF)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.greenBackground {
    background: #21ba45 !important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #21ba45, #56b26d)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #21ba45, #56b26d)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.greyBackground {
    background: #bdc3c7!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #bdc3c7, #2c3e50)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #bdc3c7, #2c3e50)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.oliveBackground {
    background: #b5cc18 !important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #b5cc18, #6f7e27)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #b5cc18, #6f7e27)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.yellowBackground {
    background: #FDC830!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #fdc830, #ffe7a1)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #fdc830, #ffe7a1)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.orangeBackground {
    background: #f37335 !important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F37335, #FDC830)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F37335, #FDC830)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.stellarBackground {
    background: #7474BF!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #348AC7, #7474BF)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #348AC7, #7474BF)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.flareBackground {
    background: #f12711!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f5af19, #f12711)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f5af19, #f12711)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.siteTitle {
    font-family: 'Nunito', monospace;
    font-weight: lighter;
    /*!*color: #00006a;*!*/
    /*background: -webkit-linear-gradient(#ffffff,#ffffff, #ffffff,#ffffff, #00006a,#ffffff, #ffffff,#ffffff);*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
    /*font-size: 24px;*/
}

/*FullCalendar*/
.fc .fc-button-primary {
    color: #fff!important;
    background-color: #2185D0!important;
    border-color: #2185D0!important;
}

.fc-button .fc-button-active {
    box-shadow: none !important;
}

.fc-button:focus {
    box-shadow: none !important;
}